import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"

import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"

//images
import house1 from "../images/remodels/house1.jpg"
import house2 from "../images/remodels/house2.jpg"
import house3 from "../images/remodels/house3.jpg"

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
})

const cards = [
  {
    img: house1,
    title: "Before",
    details:
      "This home was once a one story building without a garage or driveway.",
    link: { house1 },
  },
  {
    img: house2,
    title: "During",
    details:
      "We raised the house up over a period of time, added a basement and foundation to the home, and created a new first floor, kitchen, entryway, office, and living-room.",
  },
  {
    img: house3,
    title: "After",
    details:
      "The house underwent a full remodel, without the time or costs associated with demolition and new construction.",
  },
]

function Remodels(props) {
  const { classes } = props

  return (
    <Layout>
      <div>
        <main>
          {/* Hero unit */}
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{ fontSize: "40px" }}
              >
                REMODELS & RENOVATIONS
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                With <strong>hundreds of happy customers</strong>, American
                Dream's remodels stand head and shoulders above the competition.
                We have remodeled and Alaskan homes homes since 1997. Our work
                can help protect and increase the value of your property.
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                We have rebuilt{" "}
                <strong>
                  homes, bathrooms, garages, offices, sheds, painting, siding,
                  window installation, and warehouses of all shapes and sizes{" "}
                </strong>{" "}
                to our exacting standards for durability and comfort.{" "}
                <strong>
                  {" "}
                  We have even added additional floors to existing structures!
                </strong>{" "}
              </Typography>
              <div className={classes.heroButtons}>
                <Grid container spacing={16} justify="center">
                  <Grid item>
                    <a
                      href="mailto:info@adcalaska.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Button variant="contained" color="primary" size="large">
                        Get a quote today!
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <Grid container spacing={40}>
              {cards.map(card => (
                <Grid item key={card} xs={12} sm={12} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      alt={card.title}
                    />

                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      <Typography>{card.details}</Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Link to={{ pathname: `/ada/${card.link}` }}>
                      <Button size="small" color="primary">
                        View
                      </Button>
                    </Link> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <div className="spaceWaster" />
          </div>
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
                style={{ display: "block" }}
              >
                When you choose American Dream Construction, you're choosing
                proven quality and expert craftsmanship.
              </Typography>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

Remodels.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Remodels)
